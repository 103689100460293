import api from "../Services/Api";
import IHasIdAndName from "../Entities/IHasIdAndName";
import {observable} from "mobx";

class UnloadsLookupStore {
    @observable unloads: IHasIdAndName[] = [];
    async load() {
        const result = await api.get('unload/list');
        if (result.success) {
            this.unloads = result.data as IHasIdAndName[];
        }
    }
}

const unloadsLookupStore = new UnloadsLookupStore();
export default unloadsLookupStore;