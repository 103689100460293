import React, {useEffect} from "react";
import categoriesStore from "../../../Stores/V2/CategoriesStore";
import {observer} from "mobx-react-lite";
import CategoryStore from "../../../Stores/V2/CategoryStore";
import FieldIdSelector from "../../Shared/FieldIdSelector";
import {List} from "semantic-ui-react";

function CategoryV2SelectorTree() {
    useEffect(() => {
        void categoriesStore.load();

        return function cleanup() {
            categoriesStore.cleanup();
        }
    }, []);

    return <List style={{marginTop: '0px'}}>
        <List.Item>
            <List.Content>
                <FieldIdSelector value={categoriesStore.selectedCategory?.id}
                                 fields={categoriesStore.categories}
                                 placeholder={'Выберите категорию'}
                                 loading={categoriesStore.isLoading}
                                 search
                                 onChange={value => categoriesStore.select(value!)}/>
                {categoriesStore.selectedCategory &&
                    <Category category={categoriesStore.selectedCategory}/>
                }
            </List.Content>
        </List.Item>
    </List>
}

const Category = observer((props: { category: CategoryStore }) => {
    const {category} = props;
    if (category.children.length == 0) {
        return <></>
    }
    return <List.List>
        <List.Item>
            <List.Icon/>
            <List.Content>
                <FieldIdSelector value={category.selectedChild?.id}
                                 fields={category.children}
                                 placeholder={'Выберите категорию'}
                                 search
                                 onChange={value => category.selectChild(value!)}/>
                {category.selectedChild &&
                    <Category category={category.selectedChild}/>
                }
            </List.Content>
        </List.Item>
    </List.List>
})

export default observer(CategoryV2SelectorTree);