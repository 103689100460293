import {computed, observable} from "mobx";
import api from "../Services/Api";
import successStore from "../Stores/SuccessStore";

class RegistrationStore {
    @observable login: string = '';
    @observable password: string = '';
    @observable skladToken: string = '';
    @observable isLoading: boolean = false;
    
    changeSkladToken(value: string): void {
        this.skladToken = value;
    }
    
    changeLogin(login: string): void {
        this.login = login;
    }
    
    changePassword(password: string): void {
        this.password = password;
    }
    
    cleanup(): void {
        this.password = '';
        this.skladToken = '';
        this.login = '';
        this.isLoading = false;
    }
    
    async createUser(onSuccess: () => void) {
        this.setIsLoading(true);
        try {
            const result = await api.post('externalUser/create', {
                login: this.login,
                password: this.password,
                skladToken: this.skladToken
            });
            if (result.success) {
                successStore.add('Регистрация прошла успешно! :)')
                onSuccess();
            }
        }
        finally {
            this.setIsLoading(false);
        }
    }
    
    @computed get canCreate(): boolean {
        if (this.isLoading) return false;
        if (!this.skladToken) return false;
        if (!this.login) return false;
        if (!this.password) return false;
        
        if (this.skladToken.length < 10) return false;
        if (this.login.length < 5) return false;
        if (this.password.length < 5) return false;
        
        return true;
    }
    
    private setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}

const registrationStore = new RegistrationStore();
export default registrationStore;