import React, {useEffect} from "react";
import {Container, Form, FormButton, FormInput, Grid, Header, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import registrationStore from "./RegistrationStore";
import {NavLink, Redirect, Route, Switch, useHistory} from "react-router-dom";
import ErrorPortal from "../Components/ErrorPortal/ErrorPortal";
import SuccessPortal from "../Components/SuccessPortal";
import authPageStore from "./AuthPageStore";
import authStore from "../Stores/AuthStore";

export const routeName = "external";

function ExternalUserRouter() {
    return <Container style={{paddingTop: '30px'}}>
        <ErrorPortal/>
        <SuccessPortal/>
        <Grid centered>
            <Grid.Column width={5}>
                <Switch>
                    <Route path={`/${routeName}/register`}>
                        <RegistrationForm/>
                    </Route>
                    <Route path={`/${routeName}/auth`}>
                        <AuthForm/>
                    </Route>
                    <Route path={`/${routeName}`}>
                        <Redirect to={`/${routeName}/auth`}/>
                    </Route>
                </Switch>
            </Grid.Column>
        </Grid>
    </Container>
}

const RegistrationForm = observer(() => {
    useEffect(() => {
        return () => {
            registrationStore.cleanup();
        }
    }, []);
    const history = useHistory();
    return <Segment>
        <Header textAlign={'center'} as={'h3'}>Регистрация</Header>
        <Form>
            <FormInput label={'Логин'}
                       name={'external-login'}
                       value={registrationStore.login}
                       onChange={(e, data) => registrationStore.changeLogin(data.value)}
            />
            <FormInput label={'Пароль'}
                       name={'external-password'}
                       type={'password'}
                       value={registrationStore.password}
                       onChange={(e, data) => registrationStore.changePassword(data.value)}
            />
            <FormInput label={'Токен МойСклад'}
                       value={registrationStore.skladToken}
                       onChange={(e, data) => registrationStore.changeSkladToken(data.value)}
            />
            <FormButton fluid color={'teal'}
                        type={'submit'}
                        loading={registrationStore.isLoading}
                        disabled={!registrationStore.canCreate}
                        onClick={() => registrationStore.createUser(() => history.replace(`/${routeName}/auth`))}
            >
                Создать аккаунт
            </FormButton>
            <FormButton fluid primary as={NavLink} to={`/${routeName}/auth`}>
                <div style={{textAlign: 'center'}}>Уже есть аккаунт? Войти</div>
            </FormButton>
        </Form>
    </Segment>
});

const AuthForm = observer(() => {
    useEffect(() => {
        authStore.logOut()
        return () => {
            authPageStore.cleanup();
        }
    }, []);
    const history = useHistory();
    return <Segment>
        <Header textAlign={'center'} as={'h3'}>Вход</Header>
        <Form>
            <FormInput label={'Логин'}
                       name={'external-login'}
                       value={authPageStore.login}
                       onChange={(e, data) => authPageStore.changeLogin(data.value)}
            />
            <FormInput label={'Пароль'}
                       name={'external-password'}
                       type={'password'}
                       value={authPageStore.password}
                       onChange={(e, data) => authPageStore.changePassword(data.value)}
            />
            <FormButton fluid color={'teal'}
                        loading={authPageStore.isLoading}
                        disabled={!authPageStore.canAuth}
                        onClick={() => authPageStore.auth(() => history.replace(`/`))}>
                Войти
            </FormButton>
            <FormButton fluid primary as={NavLink} to={`/${routeName}/register`}>
                <div style={{textAlign: 'center'}}>Нет аккаунта? Регистрация</div>
            </FormButton>
        </Form>
    </Segment>
});

export default observer(ExternalUserRouter);