import IHasIdAndName from "../../Entities/IHasIdAndName";
import {Button, Dropdown, Popup} from "semantic-ui-react";
import React from "react";

function FieldIdSelector(props: {
    value?: string,
    fields: IHasIdAndName[],
    onChange(value?: string): void,
    onSearchChange?(value: string): void,
    notClearable?: boolean
    tip?: string,
    placeholder?: string,
    loading?: boolean,
    search?: boolean,
    fluid?: boolean,
}) {
    const {
        value, fields, onChange,
        notClearable,
        tip, placeholder,
        fluid,
        onSearchChange, loading, search
    } = props;
    return <>
        <Dropdown fluid={fluid ?? true}
                  placeholder={placeholder ?? 'Выберите поле'}
                  value={value || ''}
                  selection
                  loading={loading}
                  search={search || onSearchChange !== undefined}
                  onSearchChange={(_, data) => onSearchChange ? onSearchChange(data.searchQuery as string) : {}}
                  clearable={!notClearable}
                  onChange={(_, e) => onChange(e.value as string)}
                  options={fields.map(field => {
                      return {
                          key: field.id,
                          value: field.id,
                          text: field.name
                      }
                  })}
                  style={tip && {borderTopRightRadius: '0', borderBottomRightRadius: '0'}}
        />
        {tip && <Popup
            position={'bottom right'}
            content={tip}
            trigger={<Button style={{borderTopLeftRadius: '0', borderBottomLeftRadius: '0', marginRight: '0'}}
                             icon='help'/>}/>}
    </>
}

export default FieldIdSelector;