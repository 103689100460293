import api from "../Services/Api";
import IHasIdAndName from "../Entities/IHasIdAndName";
import {observable} from "mobx";

class StoresLookupStore {
    @observable stores: IHasIdAndName[] = [];
    async load() {
        const result = await api.get('store/list');
        if (result.success) {
            this.stores = result.data as IHasIdAndName[];
        }
    }
}

const storesLookupStore = new StoresLookupStore();
export default storesLookupStore;