import {computed, observable} from "mobx";
import api from "../Services/Api";

class AuthPageStore {
    @observable login: string = '';
    @observable password: string = '';
    @observable isLoading: boolean = false;
    
    changeLogin(login: string): void {
        this.login = login;
    }
    
    changePassword(password: string): void {
        this.password = password;
    }
    
    cleanup(): void {
        this.password = '';
        this.login = '';
        this.isLoading = false;
    }
    
    async auth(onSuccess: () => void) {
        this.setIsLoading(true);
        try {
            const result = await api.post('externalUser/auth', {
                login: this.login,
                password: this.password
            });
            if (result.success) {
                api.authByToken(result.data.token);
                onSuccess();
            }
        }
        finally {
            this.setIsLoading(false);
        }
    }
    
    @computed get canAuth(): boolean {
        if (this.isLoading) return false;
        if (!this.login) return false;
        if (!this.password) return false;
        
        if (this.login.length < 5) return false;
        if (this.password.length < 5) return false;
        
        return true;
    }
    
    private setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading;
    }
}

const authStore = new AuthPageStore();
export default authStore;