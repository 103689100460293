import {observable, runInAction} from "mobx";
import dashboardStore from './ProfileInfoStore';
import successStore from "./SuccessStore";
import {IntegrationIntervalDto} from "../Entities/Dtos";
import api from "../Services/Api";
import IHasIdAndName from "../Entities/IHasIdAndName";
import profileInfoStore from "./ProfileInfoStore";
import IField from "../Entities/IField";

interface ISkladStore {
    load: () => Promise<void>;
    updateUserData: () => Promise<void>;
    save: () => Promise<void>;
    tryRunJob: () => void;
    runJob: () => Promise<void>;
    stopJob: () => Promise<void>;
    jobIsStopping: boolean;
    dataUpdateIsRunning: boolean;

    customFields: IField[];
    storeFields: IField[];
    fileFields: IHasIdAndName[];

    baseFields: IHasIdAndName[];
    salePrices: IHasIdAndName[];
    characteristics: IHasIdAndName[];
    intervals: IntegrationIntervalDto[];
    intervalId?: string;
    selectedInterval: IntegrationIntervalDto | null;

    integrationField: string | null;
    bundleSalePriceId: string | null;
    notCalculateBundlePriceIfFilled: boolean;
    enableBundlePriceCalculation: boolean;
    customStockFieldId: string | null;
    enableCustomStock: boolean;

    needCheckStores: boolean;
    onlyBundles: boolean;
    needCheckStock: boolean;
    removeTransit: boolean;
    addTransit: boolean;
    removeReserve: boolean;

    fastStockCheck: boolean;
    checkRealStock: boolean;
    checkFreeStock: boolean;
    checkQuantity: boolean;

    ignoreIntegrationFieldInComponents: boolean;
    allowProductDuplications: boolean;

    isLoaded: boolean;
    loading: boolean;
    period: string;
    isSaving: boolean;
    hasChanges: boolean;

    hasCustomFields: boolean;
    showPriceInformation: boolean;
    trimLastWordInAdTitleIfLong: boolean;
    useMaths: boolean;

    minStockQuantity: number;
}

const skladStore = observable<ISkladStore>({
    onlyBundles: false,
    useMaths: false,
    showPriceInformation: false,
    jobIsStopping: false,
    dataUpdateIsRunning: false,
    hasChanges: false,
    period: '06:15',
    isLoaded: false,
    intervals: [],
    needCheckStores: false,
    needCheckStock: false,
    removeTransit: false,
    addTransit: false,
    removeReserve: false,
    trimLastWordInAdTitleIfLong: false,

    fastStockCheck: false,
    checkRealStock: false,
    checkFreeStock: false,
    checkQuantity: false,

    ignoreIntegrationFieldInComponents: false,
    allowProductDuplications: false,
    integrationField: null,
    bundleSalePriceId: null,
    customStockFieldId: null,
    enableCustomStock: false,
    notCalculateBundlePriceIfFilled: false,
    enableBundlePriceCalculation: false,
    customFields: [],
    baseFields: [],
    storeFields: [],
    fileFields: [],
    salePrices: [],
    hasCustomFields: false,
    characteristics: [],
    isSaving: false,
    loading: false,
    minStockQuantity: 1,
    async load() {
        this.loading = true;
        const result = await api.get('settings');
        runInAction(() => {
            if (result.success) {
                this.baseFields = result.data.baseFields;
                this.customFields = result.data.customFields;
                this.storeFields = result.data.storeFields;
                this.salePrices = result.data.salePrices;
                this.fileFields = result.data.fileFields;
                this.useMaths = result.data.useMaths;

                this.intervals = result.data.intervals;
                this.intervalId = result.data.intervalId;
                if (!this.intervalId && this.intervals.length > 0) {
                    this.intervalId = this.intervals[0].id;
                }

                this.integrationField = result.data.integrationField;
                this.bundleSalePriceId = result.data.bundleSalePriceId;
                this.customStockFieldId = result.data.customStockFieldId;
                this.notCalculateBundlePriceIfFilled = result.data.notCalculateBundlePriceIfFilled;

                this.enableBundlePriceCalculation = this.bundleSalePriceId !== undefined;
                this.enableCustomStock = this.customStockFieldId !== undefined;

                this.needCheckStores = result.data.needCheckStores;
                this.needCheckStock = result.data.needCheckStock;
                this.removeTransit = result.data.removeTransit;
                this.addTransit = result.data.addTransit;
                this.removeReserve = result.data.removeReserve;

                this.fastStockCheck = result.data.fastStockCheck;
                this.checkRealStock = result.data.checkRealStock;
                this.checkFreeStock = result.data.checkFreeStock;
                this.checkQuantity = result.data.checkQuantity;

                this.ignoreIntegrationFieldInComponents = result.data.ignoreIntegrationFieldInComponents;
                this.allowProductDuplications = result.data.allowProductDuplications;

                this.period = result.data.period;
                this.minStockQuantity = result.data.minStockQuantity;
                
                this.hasChanges = false;

                this.jobIsStopping = false;
                this.dataUpdateIsRunning = result.data.dataUpdateIsRunning;
                this.characteristics = result.data.characteristics;
                this.onlyBundles = result.data.onlyBundles;
                this.trimLastWordInAdTitleIfLong = result.data.trimLastWordInAdTitleIfLong;

                this.hasCustomFields = (this.customFields && this.customFields.length > 0) || (this.fileFields && this.fileFields.length > 0);
            }
            this.isLoaded = true;
            this.loading = false;
        })
    },
    async updateUserData() {
        if (this.dataUpdateIsRunning) return;

        this.dataUpdateIsRunning = true;
        await api.post('profile/updateUserData', {});
    },
    async save() {
        if (this.isSaving) return;
        if (!this.enableBundlePriceCalculation && this.bundleSalePriceId !== null || undefined) {
            this.bundleSalePriceId = null;
        }
        if (!this.enableCustomStock && this.customStockFieldId !== null || undefined) {
            this.customStockFieldId = null;
        }
        this.isSaving = true;
        const result = await api.post('settings/save', {
            integrationField: this.integrationField,
            bundleSalePriceId: this.bundleSalePriceId,
            customStockFieldId: this.customStockFieldId,
            notCalculateBundlePriceIfFilled: this.notCalculateBundlePriceIfFilled,
            needCheckStores: this.needCheckStores,
            needCheckStock: this.needCheckStock,
            removeTransit: this.removeTransit,

            fastStockCheck: this.fastStockCheck,
            checkRealStock: this.checkRealStock,
            checkFreeStock: this.checkFreeStock,
            checkQuantity: this.checkQuantity,

            ignoreIntegrationFieldInComponents: this.ignoreIntegrationFieldInComponents,
            allowProductDuplications: this.allowProductDuplications,
            addTransit: this.addTransit,
            removeReserve: this.removeReserve,
            period: this.period,
            intervalId: this.intervalId,
            onlyBundles: this.onlyBundles,
            trimLastWordInAdTitleIfLong: this.trimLastWordInAdTitleIfLong,
            useMaths: this.useMaths,
            
            minStockQuantity: this.minStockQuantity
        });

        if (result.success) {
            await dashboardStore.load();
            await this.load();
        }
        this.isSaving = false;
    },
    async runJob() {
        if (this.isSaving) return;
        this.jobIsStopping = true;

        const result = await api.post('profile/runJob', {});
        if (result.success) {
            successStore.add("Выгрузка успешно запущена");
            await dashboardStore.load();
            await this.load();
        }
    },
    async stopJob() {
        this.jobIsStopping = true;
        const result = await api.post('profile/stopJob', {});
        if (result.success) {
            await dashboardStore.load();
            await this.load();
        }
    },
    get selectedInterval(): IntegrationIntervalDto | null {
        if (this.intervals.length === 0) {
            return null;
        }
        return this.intervals.filter((interval) => interval.id === this.intervalId)[0];
    },
    tryRunJob() {
        if (profileInfoStore.jobIsRunning) {
            void this.stopJob()
        } else {
            void this.runJob();
        }
    }
});

export default skladStore;
