import {observer} from 'mobx-react-lite';
import React from 'react'
import CheckboxWithTip from "../../../Dashboard/CheckboxWithTip";
import skladStore from "../../../../Stores/SkladStore";
import StoreStockFilters from "./StoreStockFilters";
import {Form, FormField, Input} from "semantic-ui-react";

function StoreSettings() {
    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <CheckboxWithTip
                    slider
                    label='Проверять остатки на складах'
                    tip={'Если включена данная опция, то приложение будет проверять остаток товара на складах, и если остаток будет меньше минимального, то данный товар не будет попадать в выгрузку'}
                    checked={skladStore.needCheckStock}
                    onChange={(checked) => {
                        skladStore.needCheckStock = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                {skladStore.needCheckStock &&
                    <>
                        <Form style={{marginTop: '11px'}}>
                            <FormField inline>
                                <label>Минимальный остаток</label>
                                <Input value={skladStore.minStockQuantity} 
                                       onChange={(e) => {
                                           skladStore.minStockQuantity = Number(e.target.value ?? 0);
                                           skladStore.hasChanges = true;
                                       }} 
                                       type={'number'} />
                            </FormField>
                        </Form>
                        <StoreStockFilters/>
                    </>
                }
            </div>
        </>
    );
}

export default observer(StoreSettings);