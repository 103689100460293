import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AdminRouter, {routeName as adminRoute} from "./Admin/AdminRouter";
import WidgetsRouter, {routeName as widgetsRoute} from "./Widgets/WidgetsRouter";
import ExternalUserRouter, {routeName as externalUserRoute} from "./ExternalUser/ExternalUserRouter";
import Auth from "./Pages/Auth";
import authStore from "./Stores/AuthStore";
import UserRouter from "./UserRouter";
import {observer} from "mobx-react-lite";

function MainRouter() {
    return <Router>
        <Switch>
            <Route path={"/" + externalUserRoute}>
                <ExternalUserRouter/>
            </Route>
            <Route path={"/" + adminRoute}>
                <AdminRouter/>
            </Route>
            <Route path={"/" + widgetsRoute}>
                <WidgetsRouter/>
            </Route>
            <Route path="/auth">
                <Auth/>
            </Route>
            <Route path={"/"}>
                {authStore.loggedIn ? <UserRouter/> : <Redirect to={"/auth"}/>}
            </Route>
        </Switch>
    </Router>
}

export default observer(MainRouter);