import {observable} from "mobx";

export default class AvitoAccountStore {
    storeId: string;
    @observable isActive: boolean;
    @observable hasChanges: boolean = false;

    constructor(storeId: string, isActive: boolean) {
        this.storeId = storeId;
        this.isActive = isActive;
    }

    changeActive(value: boolean) {
        this.isActive = value;
        this.hasChanges = true;
    }
}
