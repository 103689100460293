import {Button, Grid, Input, Label, LabelProps, Popup} from "semantic-ui-react";
import React, {useState} from "react";
import FieldName from "../Shared/FieldName";
import FieldValue from "../Shared/FieldValue";
import copy from "copy-to-clipboard";
import {SemanticWIDTHS} from "semantic-ui-react/dist/commonjs/generic";

function TextField(props: {
    name: string
    value: string | number
    onChange?: (value: string) => void
    onChangeNumber?: (value: number) => void
    onSave?: () => void
    autoFocus?: boolean
    readOnly?: boolean
    copied?: boolean
    required?: boolean,
    helpUrl?: string,
    type?: string,
    children?: React.ReactNode,
    valueWidth?: SemanticWIDTHS,
    nameWidth?: SemanticWIDTHS,
    label?: LabelProps,
    labelPosition?: 'left' | 'right' | 'left corner' | 'right corner',
    error?: boolean,
    onContextMenu?: (e: any) => void,
    action?: boolean,
    tip?: string,
    description?: string
}) {
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const {
        name, value, onChange, onChangeNumber,
        onSave, autoFocus,
        readOnly, copied,
        required, helpUrl, type,
        children, valueWidth, nameWidth,
        label, labelPosition,
        error, onContextMenu, action,
        tip, description
    } = props;
    const hasAction = (helpUrl || copied || action || tip) as boolean;
    return <Grid.Row>
        <FieldName name={name} required={required} width={nameWidth} description={description}/>
        <FieldValue width={valueWidth}>
            <Input value={showCopiedMessage ? 'Данные скопированы!' : value}
                   type={type}
                   fluid
                   readOnly={readOnly}
                   label={label}
                   labelPosition={labelPosition}
                   autoFocus={autoFocus}
                   action={hasAction}
                   error={error}
                   onKeyDown={(e: any) => {
                       if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && onSave) {
                           onSave();
                       }
                   }}
                   onChange={(e) => {
                       if (onChange) {
                           onChange(e.target.value)
                       }

                       if (onChangeNumber) {
                           onChangeNumber(Number(e.target.value ?? 0));
                       }
                   }}
                   onContextMenu={(e: any) => {
                       if (onContextMenu) {
                           onContextMenu(e);
                       }
                   }}
            >
                {children ? children : <input/>}
                {label && <Label {...label}/> }
                {helpUrl &&
                    <Button as={'a'} icon={'help'} href={helpUrl} target="_blank" rel="noopener noreferrer"/>}
                {copied &&
                    <Button as={'a'} icon={'copy'} href={helpUrl} onClick={() => {
                        if (!showCopiedMessage) {
                            copy(value as string);
                            setShowCopiedMessage(true);
                            setTimeout(() => {
                                setShowCopiedMessage(false);
                            }, 500)
                        }
                    }}/>}
                {tip && <Popup
                    position={'bottom right'}
                    content={tip}
                    trigger={<Button style={{borderTopLeftRadius: '0', borderBottomLeftRadius: '0', marginRight: '0'}}
                                     icon='help'/>}/>}
            </Input>
        </FieldValue>
    </Grid.Row>
}

export default TextField;
