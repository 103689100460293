import api from "../../Services/Api";
import {computed, observable} from "mobx";
import {CategoryFieldDto} from "../../Entities/Dtos";
import CategoryFieldStore from "./CategoryFieldStore";

export default class CategoryEditorStore {
    @observable isSaving: boolean = false;
    @observable isDeleting: boolean = false;
    id: string;
    @observable name: string;
    @observable requiredImages: boolean;
    @observable requiredPrice: boolean;
    @observable parentId?: string;
    onSaved?: () => void;
    onDeleted?: () => void;
    @observable fields: CategoryFieldStore[] = [];
    @observable fieldsIsLoading: boolean = false;
    @observable documentationLink: string;
    @observable categoryIdForCopyFields?: string;
    @observable fieldsIsCoping: boolean = false;

    constructor(id: string, name: string, requiredImages: boolean,
                requiredPrice: boolean, documentationLink: string,
                parentId?: string,
                onSaved?: () => void, onDeleted?: () => void) {
        this.id = id;
        this.name = name;
        this.requiredImages = requiredImages;
        this.requiredPrice = requiredPrice;
        this.documentationLink = documentationLink;
        this.parentId = parentId;
        this.onSaved = onSaved;
        this.onDeleted = onDeleted;
        void this.loadFields();
    }

    async save() {
        this.setIsSaving(true);
        try {
            const result = await api.post('categoryV2/save', {
                id: this.id,
                name: this.name,
                parentCategoryId: this.parentId ? this.parentId : undefined,
                requiredImages: this.requiredImages,
                requiredPrice: this.requiredPrice,
                documentationLink: this.documentationLink
            });

            if (result.success) {
                if (this.onSaved) {
                    this.onSaved();
                }
            }
        } finally {
            this.setIsSaving(false);
        }
    }

    @computed get hasDefaultFields(): boolean {
        for (const field of defaultFields) {
            if (this.fields.find(f => f.tag === field.tag)) {
                return true;
            }
        }

        return false;
    }


    async delete() {
        this.setIsDeleting(true);
        try {
            const result = await api.post('categoryV2/delete', {
                id: this.id
            });

            if (result.success) {
                if (this.onDeleted) {
                    this.onDeleted();
                }
            }
        } finally {
            this.setIsDeleting(false);
        }
    }

    async loadFields() {
        this.setFieldsIsLoading(true);
        try {
            const result = await api.get('categoryV2/' + this.id + '/fields');
            if (result.success) {
                const fields = result.data as CategoryFieldDto[];
                this.fields = fields.map(f => new CategoryFieldStore(f.id, f.categoryId, f.name,
                    f.tag, f.order,
                    f.helpUrl, f.isRequired,
                    f.type, f.tip,
                    f.defaultValue, f.possibleValues, f.multiplyTag, () => {
                        this.loadFields();
                    }));
            }
        } finally {
            this.setFieldsIsLoading(false);
        }
    }
    
    async copyFields() {
        if (!this.canCopyFields) return;
        try {
            this.setFieldsIsCoping(true);
            await api.post('categoryV2/copyFields', {
                sourceCategoryId: this.categoryIdForCopyFields,
                destinationCategoryId: this.id
            })
        }
        finally {
            await this.loadFields();
            this.setFieldsIsCoping(false);
            this.setCategoryIdForCopyFields(undefined);
        }
    }
    
    get canCopyFields(): boolean {
        if (!this.categoryIdForCopyFields) {
            return false;
        }
        
        return !this.fieldsIsCoping;
    }
    
    setFieldsIsCoping(isCoping: boolean) {
        this.fieldsIsCoping = isCoping;
    }

    async createField() {
        try {
            await api.post('categoryV2/createField', {
                categoryId: this.id,
                tag: '',
                name: '',
                helpUrl: '',
                isRequired: false,
                type: 'String',
                order: 1000
            })
        } finally {
            await this.loadFields();
        }
    }

    async createDefaultFields() {
        try {
            for (const field of defaultFields) {
                await api.post('categoryV2/createField', {
                    categoryId: this.id,
                    tag: field.tag,
                    name: field.name,
                    helpUrl: field.helpUrl,
                    isRequired: field.isRequired,
                    type: field.type,
                    order: field.order
                });
            }
        } finally {
            await this.loadFields();
        }
    }

    changeName(name: string) {
        this.name = name;
    }

    changeParentId(id?: string) {
        this.parentId = id;
    }

    changeRequiredImages(value: boolean) {
        this.requiredImages = value;
    }

    changeRequiredPrice(value: boolean) {
        this.requiredPrice = value;
    }

    changeDocumentationLink(value: string) {
        this.documentationLink = value;
    }

    setIsSaving(isSaving: boolean) {
        this.isSaving = isSaving;
    }

    setIsDeleting(isDeleting: boolean) {
        this.isDeleting = isDeleting;
    }

    setFieldsIsLoading(value: boolean) {
        this.fieldsIsLoading = value;
    }
    
    setCategoryIdForCopyFields(id?: string) {
        this.categoryIdForCopyFields = id;
    }
}

const defaultFields = [
    {
        tag: 'Category',
        name: 'Категория объявления',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates',
        isRequired: true,
        type: 'String',
        order: 0,
    },
    {
        tag: 'GoodsType',
        name: 'Вид товара',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates',
        isRequired: true,
        type: 'String',
        order: 1,
    },
    {
        tag: 'Id',
        name: 'Идентификатор',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=xml#field-Id',
        isRequired: false,
        type: 'String',
        order: 2,
        tip: 'Заполняется автоматически.'
    },
    {
        tag: 'AvitoId',
        name: 'Номер объявления на Авито',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 3,
        tip: 'Данное поле может содержать только номер объявления с Авито или доп.поле из МойСклад, в котором прописан номер объявления с Авито.'
    },
    {
        tag: 'Title',
        name: 'Наименование',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=excel#field-Title',
        isRequired: true,
        type: 'String',
        order: 4,

    },
    {
        tag: 'AdType',
        name: 'Вид объявления',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 5,

    },
    {
        tag: 'Condition',
        name: 'Состояние',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 6,

    },
    {
        tag: 'Price',
        name: 'Цена',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 7,

    },

    {
        tag: 'VideoURL',
        name: 'Ссылка на видео с YouTube',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 90,

    },
    {
        tag: 'VideoFileURL',
        name: 'Ссылка на видеофайл',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 91
    },
    {
        tag: 'Delivery',
        name: 'Доставка',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'MultiSelectString',
        order: 92,
        tip: 'Выберите один или несколько вариантов'
    },
    {
        tag: 'DeliverySubsidy',
        name: 'Скидка на доставку',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 93,
    },
    {
        tag: 'HeightForDelivery',
        name: 'Высота товара, см. (Для доставки)',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 94,
    },
    {
        tag: 'LengthForDelivery',
        name: 'Длина товара, см. (Для доставки)',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 95,
    },
    {
        tag: 'WidthForDelivery',
        name: 'Ширина товара, см. (Для доставки)',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 96,
    },
    {
        tag: 'WeightForDelivery',
        name: 'Вес товара, кг. (Для доставки)',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 97,
    },
    {
        tag: 'InternetCalls',
        name: 'Интернет звонки через Авито',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 98,
    },
    {
        tag: 'CallsDevices',
        name: 'ID устройства на которые будут приходить интернет-звонки',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 99,
    },
    {
        tag: 'ListingFee',
        name: 'Вариант платного размещения',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 100,

    },
    {
        tag: 'DateEnd',
        name: 'Дата и время, до которых объявление актуально',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 101,

    },
    {
        tag: 'DateBegin',
        name: 'Дата и время начала размещения объявления',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 102,

    },
    {
        tag: 'AdStatus',
        name: 'Услуга продвижения',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 103,

    },
    {
        tag: 'NDS',
        name: 'НДС',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 104,

    },
    {
        tag: 'Stock',
        name: 'Остаток',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 105,

    },
    {
        tag: 'ContactMethod',
        name: 'Способ связи',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 106,

    },
    {
        tag: 'ContactPhone',
        name: 'Контактный телефон',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 107,

    },
    {
        tag: 'ManagerName',
        name: 'Имя менеджера',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/',
        isRequired: true,
        type: 'String',
        order: 108,
    },
    {
        tag: 'Address',
        name: 'Адрес',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=excel#field-Address',
        isRequired: false,
        type: 'String',
        order: 109
    },
    {
        tag: 'Description',
        name: 'Описание',
        helpUrl: 'https://www.avito.ru/autoload/documentation/templates/98189?fileFormat=excel#field-Description',
        isRequired: true,
        type: 'Text',
        order: 110
    }
];