import {observable} from "mobx";
import api from "../Services/Api";

class PermissionsStore {
    @observable useTemplates = false;
    @observable useTemplatesV2 = false;
    async load() {
        const result = await api.get('user/permissions');
        if (result.success) {
            this.useTemplates = result.data.useTemplates;
            this.useTemplatesV2 = result.data.useTemplatesV2;
        }
    }
}

const permissionsStore = new PermissionsStore();
export default permissionsStore;